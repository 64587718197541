"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../../../..");
var button_1 = require("../../../../../components/button/button");
var components_1 = require("../../../../../components");
var helpUrls_1 = require("../../../../helpUrls");
var helpButton_1 = require("../../../../../components/button/helpButton");
var EventAttendeesTile = /** @class */ (function (_super) {
    __extends(EventAttendeesTile, _super);
    function EventAttendeesTile(eventId, api, canEdit, currentUserManager, navigator) {
        var _this = _super.call(this, 'Attendees', api, currentUserManager.clientChecker.isMobileApp(), true, canEdit) || this;
        _this.eventId = eventId;
        _this.navigator = navigator;
        _this._isAttendingFieldKey = 'isAttending';
        _this._isAssignableToMeetingsFieldKey = 'isAssignableToMeetings';
        _this._needsAccommodationsKey = 'needsAccommodations';
        _this._isOpsTeamKey = 'isOpsTeam';
        _this.getAttendingCount = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, attendingCountItem, attendingString, notAttendingCountItem, notAttendingString;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Events, "/").concat(this.eventId, "/GetAttendingCount"))];
                    case 1:
                        _a._totalAttendeeCount = _b.sent();
                        attendingCountItem = this._totalAttendeeCount.filter(function (count) { return count.isAttending === true; })[0];
                        attendingString = '0';
                        if (attendingCountItem && attendingCountItem.count) {
                            attendingString = attendingCountItem.count.toString();
                        }
                        notAttendingCountItem = this._totalAttendeeCount.filter(function (count) { return count.isAttending === false; })[0];
                        notAttendingString = '';
                        if (notAttendingCountItem && notAttendingCountItem.count) {
                            notAttendingString = " | ".concat(notAttendingCountItem.count.toString());
                        }
                        this.setTitle("Attendees (".concat(attendingString).concat(notAttendingString, ")"));
                        return [2 /*return*/];
                }
            });
        }); };
        _this.editModeDeactivate = function () {
            _this.listOptionSlideContainer.unlock();
            _this.processLoadData();
        };
        _this.editModeActivate = function () {
            _this.listOptionSlideContainer.lock();
        };
        _this.buildEditForms = function () {
            var attendees = __spreadArray([], _this._attendees, true);
            _this._editForms = [];
            _this.addAttendeeForms(attendees);
        };
        _this.addAttendeeForms = function (attendees) {
            attendees = __1.ArrayUtils.arrayify(attendees);
            var _loop_1 = function (attendee) {
                var isAttendingField = new __1.CheckBoxField(_this._isAttendingFieldKey, 'Is Attending');
                var isAssignableToMeetingsField = new __1.CheckBoxField(_this._isAssignableToMeetingsFieldKey, 'In Meetings');
                isAssignableToMeetingsField.setVisibility(_this._eventManager.isMeetingsEnabled);
                var needsAccommodationsField = new __1.CheckBoxField(_this._needsAccommodationsKey, 'Requires Accommodations');
                needsAccommodationsField.setVisibility(_this._eventManager.isAccommodationsEnabled);
                var isPrivateField = new __1.CheckBoxField('isPrivate', 'Private');
                isPrivateField.isDisabled = !!attendee.eventLeadResponsibilityId;
                var billedBusinessAreaField = new components_1.NumberDropdownField('billedBusinessAreaId', 'Billed Business Area', false, _this.billableBusinessAreaOptions, null, null, components_1.FieldType.DropDown, 'Split Evenly');
                billedBusinessAreaField.setVisibility(_this.isCostManagementEnabled);
                var supportChekboxField = new __1.CheckBoxField('isSupport', 'Support');
                var eventAccessLevelDropdown = new __1.EventAccessLevelDropdown(_this.api, _this.eventId, 'eventAttendeeAccessLevelId', true);
                var isExecutiveField = new __1.CheckBoxField('isExecutive', 'Is Executive');
                var isOpsTeamField = new __1.CheckBoxField(_this._isOpsTeamKey, 'Ops Team');
                var isAdditionalHeadCount = new __1.CheckBoxField('isAdditionalHeadCount', 'Additional Head Count');
                isAdditionalHeadCount.setVisibility(_this._eventManager.canViewAdditionalHeadCount);
                var userTitleField = new components_1.UserTitlesDropdown(_this.api, 'userTitleId', 'Title', false, '');
                var fields = [
                    isAttendingField,
                    isAssignableToMeetingsField,
                    needsAccommodationsField,
                    isOpsTeamField,
                    //isPrivateField,
                    supportChekboxField,
                    isAdditionalHeadCount,
                    eventAccessLevelDropdown,
                    billedBusinessAreaField,
                    isExecutiveField,
                    userTitleField
                ];
                if (attendee.isEventProducer) {
                    for (var _a = 0, fields_1 = fields; _a < fields_1.length; _a++) {
                        var field = fields_1[_a];
                        if (field.key === 'eventAttendeeAccessLevelId') {
                            field.clearValue();
                            field.setVisibility(false);
                        }
                    }
                }
                var form = new __1.Form(fields, attendee);
                if (_this._eventManager.costManagementFeatureEnabled &&
                    !attendee.id &&
                    !attendee.billedBusinessAreaId) {
                    billedBusinessAreaField.loadingPromise.then(function () {
                        if (billedBusinessAreaField.options.some(function (option) { return option.value === attendee.businessAreaId; })) {
                            billedBusinessAreaField.setValue(attendee.businessAreaId);
                        }
                    });
                }
                isAttendingField.observationProvider.observe(function () {
                    isAssignableToMeetingsField.isDisabled = !isAttendingField.value;
                    needsAccommodationsField.isDisabled = !isAttendingField.value;
                    isOpsTeamField.isDisabled = !isAttendingField.value;
                }, components_1.FieldActions.ValueChanged);
                _this._editForms.push(form);
            };
            for (var _i = 0, attendees_1 = attendees; _i < attendees_1.length; _i++) {
                var attendee = attendees_1[_i];
                _loop_1(attendee);
            }
        };
        _this.addAttendees = function () {
            var _loop_2 = function (selectedAttendee) {
                var matchingAttendeeIndex = _this._attendees.findIndex(function (attendee) { return attendee.userId === selectedAttendee.id; });
                // if already in the attendee list
                if (matchingAttendeeIndex >= 0) {
                    // move to the top of the list
                    __1.ArrayUtils.move(_this._attendees, matchingAttendeeIndex, 0);
                    return "continue";
                }
                var newAttendee = {
                    userId: selectedAttendee.id,
                    fullName: selectedAttendee.fullName,
                    businessArea: selectedAttendee.businessAreaName,
                    businessAreaId: selectedAttendee.businessAreaId,
                    department: '',
                    email: '',
                    isAssignableToMeetings: _this._eventManager.isMeetingsEnabled,
                    isAttending: true,
                    isPrivate: false,
                    isSupport: false,
                    contactPhone: '',
                    needsAccommodations: _this._eventManager.isAccommodationsEnabled,
                    productKnowledge: '',
                    title: '',
                    eventAttendeeAccessLevelId: _this._eventManager.defaultEventAttendeeAccessLevelId,
                    references: [],
                    isExecutive: selectedAttendee.isExecutive,
                    isDeletable: true,
                    userTitleId: selectedAttendee.userTitleId,
                };
                _this._attendees.unshift(newAttendee);
                _this.addAttendeeForms(newAttendee);
                if (_this._tileData[0] && _this._tileData[0].groupHeading === 'New') {
                    _this._tileData[0].data.unshift(newAttendee);
                }
                else {
                    _this._tileData.unshift({
                        groupKeyValue: 'New',
                        groupHeading: 'New',
                        data: [newAttendee]
                    });
                }
            };
            for (var _i = 0, _a = _this.addAttendeeUserSearch.selectedResults; _i < _a.length; _i++) {
                var selectedAttendee = _a[_i];
                _loop_2(selectedAttendee);
            }
            _this._userSearchField.clearSearchResults();
            _this._userSearchField.clearSelected();
            _this.observationProvider.notifyObservers(__1.EventAttendeesTileActions.AttendeeAdded);
        };
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, businessAreasWithDepartments, _b, i;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!!this._eventManager) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.api.getEventManager(this.eventId)];
                    case 1:
                        _a._eventManager = _c.sent();
                        this._isPrivateFilter.setVisibility(this._eventManager.canViewPrivateAttendees);
                        this._inMeetingsFilter.setVisibility(this._eventManager.isMeetingsEnabled);
                        this._requiresAccommodationsFilter.setVisibility(this._eventManager.isAccommodationsEnabled);
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.api.getEventBillableBusinessAreas(this.eventId)];
                    case 3:
                        businessAreasWithDepartments = _c.sent();
                        this.billableBusinessAreaOptions = businessAreasWithDepartments.map(function (businessArea) {
                            return {
                                label: businessArea.name,
                                value: businessArea.businessAreaId
                            };
                        });
                        this.billableBusinessAreaOptions.unshift({ label: 'None', value: -1 });
                        this._originalAttendeeData = [];
                        _b = this;
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Events, "/GetEventAttendees"), this.listOptionSlideContainer.appliedFiltersModel)];
                    case 4:
                        _b._attendees = _c.sent();
                        if (!this._attendees || this._attendees.length === 0) {
                            this._noResults = true;
                        }
                        else {
                            this._originalAttendeeData = this._attendees.map(function (_a) {
                                var attendee = __rest(_a, []);
                                return attendee;
                            });
                        }
                        for (i = 0; i < this._attendees.length; i++) {
                            if (this._attendees[i].excludeFromCostManagementCalculation) {
                                this._attendees[i].billedBusinessAreaId = -1;
                                this._attendees[i].billedBusinessArea = 'None';
                            }
                        }
                        this.groupAttendees();
                        this.buildEditForms();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.removeAttendee = function (eventAttendeeId) { return function () {
            if (!eventAttendeeId) {
                var index = _this._attendees.findIndex(function (eventAttendee) { return eventAttendee.id === eventAttendeeId; });
                _this._attendees.splice(index, 1);
                _this.groupAttendees();
                _this.buildEditForms();
            }
            else {
                var index = _this._editForms.findIndex(function (eventAttendee) { return eventAttendee.model.id === eventAttendeeId; });
                _this._editForms[index].model.isRemoved = true;
                for (var _i = 0, _a = _this._editForms[index].fields; _i < _a.length; _i++) {
                    var field = _a[_i];
                    field.isDisabled = true;
                }
            }
            _this.observationProvider.notifyObservers(__1.EventAttendeesTileActions.AttendeeRemoved);
        }; };
        _this.cancelRemoveAttendee = function (eventAttendeeId) { return function () {
            var index = _this._editForms.findIndex(function (eventAttendee) { return eventAttendee.model.id === eventAttendeeId; });
            _this._editForms[index].model.isRemoved = false;
            for (var _i = 0, _a = _this._editForms[index].fields; _i < _a.length; _i++) {
                var field = _a[_i];
                field.isDisabled = false;
            }
            _this.observationProvider.notifyObservers(__1.EventAttendeesTileActions.AttendeeRemoveCancelled);
        }; };
        _this.groupAttendees = function () {
            _this._tileData = __1.ArrayUtils.groupBy(_this.listOptionSlideContainer.enabledGroups, _this._attendees, 'sortName');
            _this.observationProvider.notifyObservers(__1.EventAttendeesTileActions.AttendeesGrouped);
        };
        _this.getEditFormByUserId = function (userId) {
            return _this._editForms.filter(function (eventAttendee) { return eventAttendee.model.userId === userId; })[0];
        };
        _this.update = function () { return __awaiter(_this, void 0, void 0, function () {
            var isValid, attendeesToRemove, request, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        isValid = true;
                        this._editForms.forEach(function (form) {
                            form.submit();
                            if (!form.isValid()) {
                                isValid = false;
                            }
                        });
                        if (!isValid) return [3 /*break*/, 5];
                        attendeesToRemove = this._editForms.filter(function (form) { return form.model.isRemoved; });
                        if (!attendeesToRemove.length) return [3 /*break*/, 2];
                        request = { eventAttendeeIds: attendeesToRemove.map(function (attendee) { return attendee.model.id; }) };
                        _a = this;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.GetNonRemovableAttendees, request)];
                    case 1:
                        _a._nonRemovableAttendees = _b.sent();
                        if (!this._nonRemovableAttendees.length) {
                            this.removeConfirmationPrompt.show();
                        }
                        else {
                            this.observationProvider.notifyObservers(__1.EventAttendeesTileActions.NonRemovableAttendeesSet);
                        }
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.confirmUpdate()];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.saveButton.setProcessing(false);
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        _this.confirmUpdate = function () { return __awaiter(_this, void 0, void 0, function () {
            var attendeesPayload;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        attendeesPayload = this._editForms
                            .filter(function (form) { return _this.includeInPayload(form.model); })
                            .map(function (form) { return form.model; });
                        if (!attendeesPayload || !attendeesPayload.length) {
                            this.saveButton.setProcessing(false);
                            this.exitEditModeButton.click();
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.api.put(__1.EndpointUrls.UpdateEventAttendees, this.eventId, attendeesPayload)];
                    case 1:
                        _a.sent();
                        window.location.reload();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.buildRemoveAttendeeButton = function (attendee) {
            if (!_this.isEditMode ||
                attendee.isRemoved ||
                attendee.isEventProducer ||
                !attendee.isDeletable) {
                return null;
            }
            return new button_1.default({
                leftIcon: __1.Icons.Minus,
                onClick: _this.removeAttendee(attendee.id)
            });
        };
        _this.buildCancelRemoveAttendeeButton = function (attendee) {
            if (!_this.isEditMode ||
                !attendee.isRemoved) {
                return null;
            }
            return new button_1.default({
                leftIcon: __1.Icons.Plus,
                onClick: _this.cancelRemoveAttendee(attendee.id)
            });
        };
        _this.buildShowReferencesAlertButtion = function (attendee) {
            if (!_this.isEditMode ||
                attendee.isEventProducer ||
                attendee.isDeletable ||
                (_this.attendeeReferencesAlerts[attendee.userId] &&
                    _this.attendeeReferencesAlerts[attendee.userId].isVisible)) {
                return null;
            }
            return new button_1.default({
                leftIcon: __1.Icons.Alert,
                onClick: function () { return _this.showReferencesAlert(attendee); }
            });
        };
        _this.hideReferenceAlert = function (attendee) {
            _this.attendeeReferencesAlerts[attendee.userId].hide();
            _this.observationProvider.notifyObservers(__1.EventAttendeesTileActions.AttendeeReferenceAlertHidden);
        };
        _this.hideUnableToRemoveAttendeesModal = function () {
            _this._nonRemovableAttendees = null;
            _this.observationProvider.notifyObservers(__1.EventAttendeesTileActions.NonRemovableAttendeesCleared);
        };
        _this._userSearchField = new __1.UserSearchField(api, 'User Search', false, null, true);
        _this._helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.AttendeesTile, null, navigator, api);
        _this._isPrivateFilter = new __1.BooleanDropdownField('isPrivate', 'Is Private', false);
        _this._isPrivateFilter.setVisibility(false);
        _this._inMeetingsFilter = new __1.BooleanDropdownField('inMeetings', 'In meetings', false);
        _this._inMeetingsFilter.setVisibility(false);
        _this._requiresAccommodationsFilter = new __1.BooleanDropdownField('requiresAccommodations', 'Requires Accommodations', false);
        _this._requiresAccommodationsFilter.setVisibility(false);
        var filters = [
            new __1.TextField('name', 'Attendee name', false, 200),
            new __1.EmailField('email', false, 'Attendee email'),
            new __1.BusinessAreaDropdown(_this.api, 'businessAreaId', 'Business area', false, null),
            new __1.DepartmentDropdown(_this.api, 'departmentId', false, null, 'Operating company'),
            new __1.LinesOfBusinessDropdown(_this.api, 'lineOfBusinessId', false),
            new components_1.ProductKnowledgeDropdownField(_this.api, 'productKnowledgeOptionId', 'Area of Expertise', false),
            new __1.EventAccessLevelDropdown(_this.api, _this.eventId, 'eventAttendeeAccessLevelId', false, null, 'Access level'),
            new __1.BooleanDropdownField('isAttending', 'Is Attending', false),
            new __1.BooleanDropdownField('isSupport', 'Is Support', false),
            _this._isPrivateFilter,
            _this._inMeetingsFilter,
            _this._requiresAccommodationsFilter
        ];
        var groupByOptions = [
            {
                key: 'businessArea',
                label: 'Business area',
                enabled: true,
                headingBuilder: function (attendee, businessAreaAttendees) {
                    var heading = attendee.businessArea;
                    var attendingCount = businessAreaAttendees.filter(function (a) { return a.isAttending; }).length;
                    var notAttendingCount = businessAreaAttendees.filter(function (a) { return !a.isAttending; }).length;
                    if (notAttendingCount) {
                        heading += " (".concat(attendingCount, " | ").concat(notAttendingCount, ")");
                    }
                    else {
                        heading += " (".concat(attendingCount, ")");
                    }
                    return heading;
                }
            },
            { key: 'department', label: 'Operating company', enabled: false },
            { key: 'lineOfBusiness', label: 'Line of business', defaultHeading: 'Unknown', enabled: true },
            { key: 'productKnowledge', label: 'Area of Expertise', defaultHeading: 'Unknown', enabled: false }
        ];
        var myBusinessAreaFilterId = null;
        if (currentUserManager.userPreferences &&
            currentUserManager.userPreferences.defaultMyBusinessAreaFilterOnAttendeesTileEnabled) {
            myBusinessAreaFilterId = currentUserManager.businessAreaId;
        }
        var defaultFilters = {
            eventId: eventId,
            businessAreaId: myBusinessAreaFilterId
        };
        _this.listOptionSlideContainer = new __1.ListOptionSlideContainer(filters, defaultFilters, groupByOptions, ['eventId']);
        _this.addAttendeesButton = new button_1.default({
            leftIcon: __1.Icons.Plus,
            text: 'Add Attendee',
            onClick: _this.addAttendees
        });
        _this.listOptionSlideContainer.observationProvider.observe(_this.groupAttendees, __1.ListOptionSlideContainerActions.GroupSelectionRemoved);
        _this.listOptionSlideContainer.observationProvider.observe(_this.processLoadData, __1.ListOptionSlideContainerActions.OptionsApplied);
        _this.observationProvider.observe(_this.editModeDeactivate, __1.EventAttendeesTileActions.EditModeDeactivated);
        _this.observationProvider.observe(_this.editModeActivate, __1.EventAttendeesTileActions.EditModeActivated);
        _this.getAttendingCount();
        _this.attendeeReferencesAlerts = [];
        _this.removeConfirmationPrompt = new components_1.ConfirmationPrompt(true, 'Remove Attendee(s)', 'Are you sure you want to remove the selected attendees? When removed from the event, attendees will be removed from meetings and all accommodations will be cancelled.', 'Remove Attendee(s)', 'Cancel');
        _this.removeConfirmationPrompt.observationProvider.observe(_this.confirmUpdate, components_1.ConfirmationPromptActions.Confirmed);
        _this.hideUnableToRemoveAttendeesModalButton = new button_1.default({
            text: 'Close',
            onClick: _this.hideUnableToRemoveAttendeesModal
        });
        return _this;
    }
    Object.defineProperty(EventAttendeesTile.prototype, "totalAttendeeCount", {
        get: function () {
            return this._totalAttendeeCount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventAttendeesTile.prototype, "tileData", {
        get: function () {
            return this._tileData;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventAttendeesTile.prototype, "editForms", {
        get: function () {
            return this._editForms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventAttendeesTile.prototype, "addAttendeeUserSearch", {
        get: function () {
            return this._userSearchField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventAttendeesTile.prototype, "noResults", {
        get: function () {
            return this._noResults;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventAttendeesTile.prototype, "nonRemovableAttendees", {
        get: function () {
            return this._nonRemovableAttendees;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventAttendeesTile.prototype, "isCostManagementEnabled", {
        get: function () {
            return this._eventManager && this._eventManager.isCostManagementEnabled;
        },
        enumerable: false,
        configurable: true
    });
    EventAttendeesTile.prototype.includeInPayload = function (attendee) {
        if (!attendee.id || attendee.isRemoved) {
            return true;
        }
        var original = this._originalAttendeeData.find(function (orig) { return orig.id === attendee.id; });
        var billedBusinessAreaChanged = false;
        if ((original.billedBusinessAreaId !== null && original.billedBusinessAreaId >= 0) ||
            (attendee.billedBusinessAreaId !== null && attendee.billedBusinessAreaId >= 0)) {
            billedBusinessAreaChanged = original.billedBusinessAreaId !== attendee.billedBusinessAreaId;
        }
        else {
            billedBusinessAreaChanged = (original.excludeFromCostManagementCalculation && attendee.billedBusinessAreaId !== -1) || (!original.excludeFromCostManagementCalculation && attendee.billedBusinessAreaId !== null);
        }
        return original.isAttending !== attendee.isAttending ||
            original.isAssignableToMeetings !== attendee.isAssignableToMeetings ||
            original.needsAccommodations !== attendee.needsAccommodations ||
            original.isPrivate !== attendee.isPrivate ||
            original.isSupport !== attendee.isSupport ||
            original.eventAttendeeAccessLevelId !== attendee.eventAttendeeAccessLevelId ||
            original.isExecutive !== attendee.isExecutive ||
            original.isOpsTeam !== attendee.isOpsTeam ||
            original.isAdditionalHeadCount !== attendee.isAdditionalHeadCount ||
            original.userTitleId !== attendee.userTitleId ||
            billedBusinessAreaChanged;
    };
    EventAttendeesTile.prototype.showReferencesAlert = function (attendee) {
        var _this = this;
        if (!this.attendeeReferencesAlerts[attendee.userId]) {
            this.attendeeReferencesAlerts[attendee.userId] = new __1.Alert();
        }
        var referenceAlert = this.attendeeReferencesAlerts[attendee.userId];
        referenceAlert.show({
            alertType: __1.AlertType.Error,
            heading: 'Unable to Remove',
            message: 'This attendee has the following references which must be removed before deleting.',
            button: new button_1.default({
                text: 'Close',
                onClick: function () { return _this.hideReferenceAlert(attendee); }
            })
        });
        this.observationProvider.notifyObservers(__1.EventAttendeesTileActions.AttendeeReferenceAlertShown);
    };
    return EventAttendeesTile;
}(__1.EventLandingTile));
exports.default = EventAttendeesTile;
