import { h, JSX } from 'preact';
import EventInfoDetailTileProps from './eventInfoDetailTileProps';
import EventInfoTile from './eventInfoTile';
import EventInfoForm from './eventInfoForm';
import { DateUtils, DateFormats, TimezoneUtils, EventTypes } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import PhoneButton from '../buttons/phoneButton';
import EmailButton from '../buttons/emailButton';
import FormattedDate from '../text/formattedDate';
import Alert from '../alerts/alert';

class EventInfoDetailTile extends ObservingComponent<EventInfoDetailTileProps> {

    public componentWillMount(): void {
        const { tile } = this.props;

        this.registerUpdateObserver(tile.observationProvider);
        this.registerUpdateObserver(tile.eventInfoForm.observationProvider);
    }

    public render({ tile }: EventInfoDetailTileProps): JSX.Element {
        return (
            <EventInfoTile
                color="light-blue"
                tile={tile}
                icon="ti-event">

                    {tile.isEditMode}


                    { tile.isEditMode && !tile.isLoading ? (
                        tile.isEditFormSaving ?
                            'Loading...'
                        : (
                            <div className="mt-1">
                                <EventInfoForm eventInfoForm={tile.eventInfoForm}></EventInfoForm>
                            </div>
                        )
                    ) : (
                        tile.isLoading ?
                            'Loading...'
                        : (
                            <div>
                                <p>
                                    <div class="flex">
                                        <b>{tile.tileData.name}</b> {TimezoneUtils.format(tile.tileData.timezone)}

                                        {tile.currentEventDateTime &&
                                            <div class="ml-auto">
                                                Current time at event
                                                <span>&nbsp;</span>
                                                <FormattedDate
                                                    date={tile.currentEventDateTime}
                                                    format={DateFormats.hhCmm} />
                                            </div>
                                        }
                                    </div>
                                </p>
                                <p>
                                    {tile.tileData.isInternational ? <span>International Event</span> : <span>Domestic Event</span>}
                                </p>
                                <p>
                                    <b>{DateUtils.formatDate(tile.tileData.startDate, DateFormats.MMMMd)} - {DateUtils.formatDate(tile.tileData.endDate, DateFormats.MMMMd)}</b>
                                </p>
                                <p>
                                    <b>Location:</b>
                                    <br/>
                                    <span class="pl-2">
                                        {tile.tileData.address}
                                    </span>
                                    <br/>
                                    <span class="pl-2">

                                        {tile.tileData.city}, <span> </span>

                                        <span>
                                            {tile.tileData.state}
                                        </span>

                                        {tile.tileData.zipCode &&
                                            <span>, {tile.tileData.zipCode}</span>
                                        }
                                    </span>
                                    <br/>
                                    <span class="pl-2">
                                        {tile.tileData.country}
                                    </span>
                                </p>
                                <p>
                                    <b>Web Links:</b>
                                    {
                                        tile.tileData.webLinks.length ?
                                            tile.tileData.webLinks.map((webLink) => {
                                                return (
                                                    <span>
                                                        <br/>
                                                        <span class="pl-2">
                                                            <a
                                                                href={webLink.url}
                                                                target="_blank">

                                                                {webLink.text}
                                                            </a>
                                                        </span>
                                                    </span>
                                                )
                                            }) :

                                            <span class="text-center"> No Web Links</span>
                                    }
                                </p>
                                <p>
                                    <b>Exhibit Hours:</b> {tile.tileData.exhibitStartTime} - {tile.tileData.exhibitEndTime}
                                </p>
                                <p class="flex">
                                    <span class="fill">
                                        <b>Event Producer:</b> {tile.tileData.eventProducerName}
                                    </span>

                                    {tile.tileData.eventProducerContactPhone && (
                                        <PhoneButton phoneNumber={tile.tileData.eventProducerContactPhone} />
                                    )}

                                    <EmailButton emailAddress={tile.tileData.eventProducerEmail} />
                                </p>
                                <p>
                                    <b>Lead BA:</b> {tile.tileData.leadBusinessAreaName}
                                </p>
                                <p>
                                    <b>Event Leads:</b>
                                        { tile.tileData.eventLeads.length ? (

                                            <ul class="data-list">
                                                {tile.tileData.eventLeads.map((eventLead) => (
                                                        <li class="flex">
                                                            <span class="fill">
                                                                <b>
                                                                { eventLead.id ? eventLead.businessArea + ' / ' : '' }
                                                                { eventLead.eventLeadResponsibilityName } : </b>

                                                                <span>{eventLead.fullName} </span>
                                                            </span>

                                                            {eventLead.contactPhone && (
                                                                <PhoneButton phoneNumber={eventLead.contactPhone} />
                                                            )}

                                                            <EmailButton emailAddress={eventLead.email} />
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        ) :
                                            <span class="text-center"> No Event Leads</span>
                                        }
                                </p>
                                <p>
                                    <b>Strategy:</b> {tile.tileData.strategyComments}
                                </p>
                                <p>
                                    <b>Objective:</b> {tile.tileData.objectiveComments}
                                </p>
                                <p>
                                    <b>Notes:</b> {tile.tileData.notesComments}
                                </p>

                                <p>
                                    <b>Exhibit Spaces:</b>

                                    {!tile.tileData.exhibitSpaces.length && <span> No exhibit spaces assigned</span>}

                                    {tile.tileData.exhibitSpaces.length > 0 &&
                                        <ul class="data-list">
                                            {tile.tileData.exhibitSpaces.map((exhibitSpace) => (
                                                <li>
                                                    <b>
                                                        {exhibitSpace.name}
                                                    </b>
                                                    {exhibitSpace.isPrimary && <i> Primary</i>}
                                                    <br/>
                                                    Stand: {exhibitSpace.standNumber}
                                                    <br/>
                                                    Total Floor Space:  {exhibitSpace.totalFloorSpace} Sq. Ft.
                                                    <br/>
                                                    Description: {exhibitSpace.description}
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </p>
                            </div>
                    )
                )}
            </EventInfoTile>
        );
    }
}

export default EventInfoDetailTile;
