import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import RoomStatusDisplayProps from './roomStatusDisplayProps';
import Modal from '../containers/modal';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import Alert from '../alerts/alert';
import Field from '../fields/field';
import FormattedDate from '../text/formattedDate';
import { DateFormats, DateUtils, MeetingDisplayItem } from 'core.frontend';
import QRCode from 'react-qr-code';

class RoomStatusDisplayPage extends ObservingComponent<RoomStatusDisplayProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    private getMeetingOffsetMinutes = (startDateTime: Date) => {
        /* calculates the number of minutes a meeting start time is before the first time on the grid */
        var offset = DateUtils.getMinutesDifference(this.props.page.firstGridDateTime, startDateTime);
        return offset > 0 ? offset : 0;
    }

    private getMeetingBlockWidth = (meeting: MeetingDisplayItem) => {
        let lengthInMinutes = DateUtils.getMinutesDifference(meeting.endDateTime, meeting.startDateTime);
        lengthInMinutes -= this.getMeetingOffsetMinutes(meeting.startDateTime);

        return (lengthInMinutes / 30 * 100);
    }

    private getMeetingBlockLeft = (meeting: MeetingDisplayItem) => {
        let minutesFromBase = DateUtils.getMinutesDifference(meeting.startDateTime, this.props.page.firstGridDateTime);
        minutesFromBase += this.getMeetingOffsetMinutes(meeting.startDateTime);

        return minutesFromBase / 30 * 100;
    }

    public render({ page }: RoomStatusDisplayProps): JSX.Element {
        return (
            <div class="display-page-wrapper">
                <Alert
                    className="mb-0"
                    alert={page.alert} />

                {page.settingsVisible &&
                    <Modal
                        heading="Room Status Display Settings"
                        close={() => {}}
                        hideBackButton={true}
                        footerRightButtons={[
                            <Button
                                color={ButtonColors.Primary}
                                button={page.submitButton} />
                        ]}>

                        <div class="form-container">
                            <p>
                                <b>Note: </b> Only finalized meetings will be displayed. Refresh the page to access these settings again.
                            </p>

                            <h4 class="section-header">Display Options</h4>

                            <div>
                                <Field
                                    field={page.form.fields[0]} />

                                <Field
                                    field={page.form.fields[1]} />
                            </div>
                        
                            <h4 class="section-header">QR Code</h4>

                            <p class="text-sm">Use the QR code below to load on other devices. </p>

                            <div class="flex mt-1">
                                <div class="ml-auto mr-auto bg-white">
                                    <QRCode value={window.location.href} />
                                </div>
                            </div>
                        </div>
                    </Modal>
                }

                {!page.settingsVisible &&
                    <div class="meeting-display">
                        <div class="meeting-display-header">
                            <div>{page.meetingDisplayData.eventName}</div>

                            <FormattedDate
                                className="meeting-display-date"
                                date={page.meetingDisplayData.currentDateTimeOffset}
                                format={DateFormats.MMMdYYYY} />

                            <FormattedDate
                                className="meeting-display-time"
                                date={page.meetingDisplayData.currentDateTimeOffset}
                                format={DateFormats.hhCmm}/>
                        </div>
                        <div class="meeting-display-content">

                            {page.form.model.displayMeetingList &&
                                <div class={'meeting-display-list ' + (!page.form.model.displayMeetingGrid ? 'full' : '')}>
                                    <table>
                                        <thead>
                                            <th class="fit-content">Start Time</th>
                                            <th class="fit-content">Meeting #</th>
                                            <th>Room</th>
                                        </thead>
                                        <tbody>
                                            {page.meetingDisplayData.meetings.map((meeting) =>
                                                <tr>
                                                    <td class="text-center fit-content">
                                                        <FormattedDate
                                                            format={DateFormats.hhCmm}
                                                            date={meeting.startDateTime} />

                                                        <span> - </span>

                                                        <FormattedDate
                                                            format={DateFormats.hhCmm}
                                                            date={meeting.endDateTime} />
                                                    </td>
                                                    <td class="text-center">{meeting.meetingNumber}</td>
                                                    <td>{meeting.venueName}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {page.form.model.displayMeetingGrid &&
                                <div class="meeting-display-grid">
                                    <table>
                                        <thead>
                                            <th></th>
                                            {page.gridTimes.map((time) =>
                                                <th class="meeting-display-grid-time">
                                                    <b>{time}</b>
                                                </th>
                                            )}
                                        </thead>
                                        <tbody>
                                            {page.venues.map((venue) =>
                                                <tr>
                                                    <td>
                                                        <b>{venue.name}</b>
                                                    </td>

                                                    <td
                                                        class="meeting-container-cell"
                                                        colSpan={100}>

                                                        {page.meetingDisplayData.meetings.map((meeting) => {

                                                            if (meeting.venueId !== venue.id) {
                                                                return;
                                                            }

                                                            return (
                                                                <span
                                                                    class="meeting-block"
                                                                    style={{
                                                                        'left': this.getMeetingBlockLeft(meeting),
                                                                        'width': this.getMeetingBlockWidth(meeting)
                                                                    }}>

                                                                    <span>{meeting.meetingNumber}</span>
                                                                </span>
                                                            );
                                                        })}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default RoomStatusDisplayPage;
