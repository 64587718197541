import { Fragment, h, JSX } from 'preact';
import EventAttendeesTileProps from './eventAttendeesTileProps';
import EventInfoTile from './eventInfoTile';
import ObservingComponent from '../componentBases/observingComponent';
import Field  from '../fields/field';
import TagCollection from '../tags/tagCollection';
import GroupContainer from '../groupContainers/groupContainer';
import ListOptionSlideContainer from './rightSlider/listOptionSlideContainer';
import SearchField from '../fields/searchField';
import EmptyState from '../uiStates/emptyState';
import ButtonColors from '../buttons/buttonColors';
import PhoneButton from '../buttons/phoneButton';
import EmailButton from '../buttons/emailButton';
import Button from '../buttons/button';
import ButtonSizes from '../buttons/buttonSizes';
import Alert from '../alerts/alert';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import Modal from '../containers/modal';
import FormattedDate from '../text/formattedDate';
import FormattedTimespan from '../text/formattedTimespan';

class EventAttendeesTile extends ObservingComponent<EventAttendeesTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider);
    }

    public render({ tile }: EventAttendeesTileProps): JSX.Element {
        return (
            <EventInfoTile
                color="tan"
                tile={tile}
                icon="ti-attendees">

                { tile.isLoading ?
                        'Loading...'
                    : (
                        <div>
                            <ConfirmationPrompt confirmationPrompt={tile.removeConfirmationPrompt} />

                            {tile.nonRemovableAttendees && tile.nonRemovableAttendees.length &&
                                <Modal
                                    heading={"Unable to remove attendees"}
                                    close={tile.hideUnableToRemoveAttendeesModal}
                                    footerRightButtons={[
                                        <Button
                                            color={ButtonColors.Secondary}
                                            button={tile.hideUnableToRemoveAttendeesModalButton} />
                                    ]} >
                                        <div class="pt-1">
                                            <div class="mb-2">The following attendees are assigned as the point of contact for at least one meeting and cannot be removed from the event.</div>

                                            {tile.nonRemovableAttendees.map((attendee) =>
                                                <div class="non-removable-attendee-info">
                                                    <div>
                                                        Attendee: <b>{attendee.fullName}</b>
                                                    </div>
                                                    <div>
                                                        BA: {attendee.businessAreaName}
                                                    </div>

                                                    <table class="modal-table mt-1">
                                                        <thead>
                                                            <tr>
                                                                <th class="fit-content text-center">#</th>
                                                                <th>Meeting Name</th>
                                                                <th class="text-center">Date / time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {attendee.meetings.map((meeting) =>
                                                                <tr>
                                                                    <td class="fit-content text-center">{meeting.number}</td>
                                                                    <td>{meeting.name}</td>
                                                                    <td class="text-center">
                                                                        <FormattedDate date={meeting.date} />

                                                                        &nbsp;

                                                                        <FormattedTimespan timespan={meeting.startTime} />
                                                                        <span> - </span>
                                                                        <FormattedTimespan timespan={meeting.endTime} />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>
                                </Modal>
                            }

                            <div class="flex mt-1">
                                <div class="fill">
                                    <TagCollection tagCollection={tile.listOptionSlideContainer.groupByTagCollection} />
                                    <TagCollection tagCollection={tile.listOptionSlideContainer.filterTagCollection} />
                                </div>

                                <Button
                                    className="ml-auto btn-icon btn-no-inner-margin"
                                    color={ButtonColors.Secondary}
                                    button={tile.listOptionSlideContainer.openButton} />
                            </div>

                            <div class="mt-1">
                                {tile.isEditMode &&
                                    <div class="form-container search-field-wrapper pb-1">
                                        <SearchField
                                            className="v-center no-label"
                                            searchField={tile.addAttendeeUserSearch} />

                                        <Button
                                            size={ButtonSizes.Small}
                                            color={ButtonColors.Primary}
                                            button={tile.addAttendeesButton} />
                                    </div>
                                }

                                <GroupContainer
                                    arrayGroups={tile.tileData}
                                    hideHeaderOnNoGroup={true}

                                    headingTemplate={(group) => (
                                        <div class="attendee-header">
                                            {group.groupHeading}
                                        </div>
                                    )}

                                    itemWrapperTemplate={(group, itemTemplate) => (
                                        <ul class="mt-0 mb-0 data-list">
                                            {itemTemplate}
                                        </ul>
                                    )}

                                    itemTemplate={(attendee, index) => (
                                        <li
                                            key={attendee.userId}
                                            class={attendee.isRemoved ? 'deleting' : ''}>


                                            {tile.isEditMode &&
                                            tile.attendeeReferencesAlerts[attendee.userId] &&

                                                <Alert
                                                    className="mb-p5"
                                                    alert={tile.attendeeReferencesAlerts[attendee.userId]}>
                                                    <div class="text-sm mt-p5">
                                                        {attendee.references && attendee.references.map((reference) => {
                                                            if (reference.referenceCount > 0) {
                                                                return <div>
                                                                    Referenced to: {reference.tableName} | Number of references: {reference.referenceCount}
                                                                </div>
                                                            }
                                                        })}
                                                    </div>
                                                </Alert>
                                            }

                                            <div class="flex">
                                                <span class="fill flex">
                                                    
                                                    <span class="flex">
                                                        <span>{attendee.fullName}</span>
                                                        {!attendee.isAttending && <i class="v-center text-sm text-red">&nbsp;- Not Attending</i>}
                                                        {attendee.isEventProducer && <i class="v-center text-sm text-bold">&nbsp;- Event Producer</i> }
                                                        {attendee.isSupport && <i class="v-center text-sm text-bold">&nbsp;- Support</i> }
                                                        {attendee.isOpsTeam && <i class="v-center text-sm text-bold">&nbsp;- Ops Team</i> }
                                                        {attendee.isAdditionalHeadCount && <i class="v-center text-sm text-bold">&nbsp;- Additional Head Count</i>}

                                                        {tile.isEditMode &&
                                                            // Is Executive 
                                                            <Field
                                                                className='v-center ml-1'
                                                                field={tile.getEditFormByUserId(attendee.userId).fields[8]} />
                                                        }
                                                    </span>

                                                    <Button
                                                        className="btn-remove-data-item"
                                                        button={tile.buildRemoveAttendeeButton(attendee)} />

                                                    <Button
                                                        className="btn-remove-data-item"
                                                        button={tile.buildCancelRemoveAttendeeButton(attendee)} />

                                                    <Button
                                                        className="btn-remove-data-item"
                                                        button={tile.buildShowReferencesAlertButtion(attendee)} />
                                                </span>

                                                {!tile.isEditMode && attendee.contactPhone && (
                                                    <PhoneButton phoneNumber={attendee.contactPhone} />
                                                )}


                                                {!tile.isEditMode &&
                                                    <EmailButton emailAddress={attendee.email} />
                                                }

                                            </div>                                                    

                                            {!tile.isEditMode &&
                                                <div>
                                                    <div class="text-sm">
                                                        <b>Title: </b>
                                                    
                                                        <span>
                                                            { attendee.title ? <span>{attendee.title}</span> : <i>Unknown</i> }
                                                        </span>
                                                    
                                                        {attendee.isExecutive && <span> - Executive</span>}
                                                    </div>

                                                    <div class="text-sm">
                                                        <b>Area of Expertise: </b>
                                                        {attendee.productKnowledge ? <span>{attendee.productKnowledge}</span> : <i>Unknown</i> }
                                                    </div>

                                                    {attendee.id && tile.isCostManagementEnabled &&
                                                        <div class="text-sm">
                                                            <b>Billed BA: </b>
                                                            {!!attendee.billedBusinessArea && <span>{attendee.billedBusinessArea}</span>}
                                                            {!attendee.billedBusinessArea && <span>Split Evenly</span>}
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            {tile.isEditMode &&
                                                <div>
                                                    <div class="flex mt-p5 text-sm">
                                                            
                                                        {/* // User Title */}
                                                        <Field
                                                            className="inline-label input-xs fill v-center" 
                                                            field={tile.getEditFormByUserId(attendee.userId).fields[9]} />
                                                        
                                                        {/* // Access Level */}
                                                        {!attendee.isEventProducer &&
                                                            <Field
                                                                field={tile.getEditFormByUserId(attendee.userId).fields[6]}
                                                                className="fill ml-1 input-xs inline-label v-center" />
                                                        }

                                                        {/* // Billed Business Area */}
                                                        <Field
                                                            field={tile.getEditFormByUserId(attendee.userId).fields[7]}
                                                            className="fill ml-1 input-xs inline-label v-center" />
                                                    </div>

                                                    <div class="flex mt-1 pb-1 wrap space-between attendee-settings-checkboxes">
                                                        {tile.getEditFormByUserId(attendee.userId).fields.map((field, index) => {
                                                            if (index > 5) {
                                                                return;
                                                            }

                                                            return <Field
                                                                field={field}
                                                                className="stacked-label" />
                                                        })}
                                                    </div>
                                                </div>
                                            }
                                        </li>
                                    )}>
                                </GroupContainer>

                                {tile.noResults &&
                                    <EmptyState message="No matching records found" />
                                }
                            </div>

                            <ListOptionSlideContainer
                                headerText = "Event Attendee List Options"
                                listOptionSlideContainer = { tile.listOptionSlideContainer } />
                        </div>
                    )
                }
            </EventInfoTile>
        );
    }
}

export default EventAttendeesTile;
